// src/components/Stamp/Stamp.js

import React from 'react';
import './Stamp.css';
import tocImage from './images/main.png'; // Adjust the path to your image file

const Stamp = () => {
  return (
    <div className="stamp">
      <div className="stamp-row">
        <img src={tocImage} alt="Total Supply" className="stamp-image" />
      </div>
      <div className="disclaimer">
        <p>
        Disclaimer: This is a meme coin and is not intended to be financial advice. Please invest responsibly and understand the inherent risks associated with cryptocurrencies.

Additionally, please note that the development wallet will be used exclusively for marketing and to support the progress of the project. All transactions will be transparently documented and pinned in our Telegram channel for full accountability.
        </p>
      </div>
    </div>
  );
};

export default Stamp;
