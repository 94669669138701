// src/components/Toconomics/Toconomics.js

import React from 'react';
import './Toconomics.css';
import supply from './images/idea.png'; // Adjust the path to your image file
import mint from './images/idea1.png'; // Adjust the path to your image file
import burn from './images/idea2.png'; // Adjust the path to your image file

const Toconomics = () => {
  return (
    <div className="toconomics">
      <h2 className="toconomics-headline">Toconomics</h2>
      <div className="toconomics-row">
        <div className="toconomics-item">
          <img src={supply} alt="Total Supply" className="toconomics-image" />
        </div>
        <div className="toconomics-item">
          <img src={mint} alt="No Minting" className="toconomics-image" />
        </div>
        <div className="toconomics-item">
          <img src={burn} alt="Burn" className="toconomics-image" />
        </div>
      </div>
    </div>
  );
};

export default Toconomics;
