// src/components/Header/Header.js

import React from 'react';
import './Header.css';
import videoSrc from './video/popeye.mp4'; // Adjust the path to your video file
import logo from './images/main.png'; // Adjust the path to your logo image
import dex from './images/dex.png'; // Adjust paths for the additional images
import dext from './images/dextool.png';
import bird from './images/bird.png';
import ray from './images/ray.webp';
import jup from './images/jup.png';

// <a href="https://link3.com"><img src={ray} alt="Raydium" /></a>
// <a href="https://link3.com"><img src={jup} alt="Jupter" /></a>
const Header = () => {
  return (
    <header>
      <video autoPlay loop muted className="header-video">
        <source src={videoSrc} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="header-content">
        <img src={logo} alt="Logo" className="header-logo" />
        <div className="header-links">
          <a href="https://link1.com"><img src={dex} alt="Dexscreener" /></a>
          <a href="https://link3.com"><img src={bird} alt="Birdeye" /></a>
          
  
        </div>
      </div>
    </header>
  );
};

export default Header;
