// src/components/About/About.js

import React from 'react';
import './About.css';
import image1 from './images/image1.png'; // Adjust the path to your image file
import image2 from './images/image2.png'; // Adjust the path to your image file
import image3 from './images/image3.png'; // Adjust the path to your image file
import image4 from './images/image4.png'; // Adjust the path to your image file
import image5 from './images/hero.png'; // Adjust the path to your image file
const About = () => {
  return (
    <div className="about">
      <div className="image-text-image">
        <img src={image5} alt="Image 1" className="image" />
      </div>
      <div className="about-text-container">
        <img src={image3} alt="Image 3" className="image large left" />
        <div className="about-text">
          <h3>Ahoy, Spinach Lovers and Seafarers!</h3>
          <p>Get ready to set sail on an epic adventure with everyone's favorite sailor man, Popeye! With his trusty can of spinach, Popeye has been punching above his weight and outwitting villains since 1929. And guess what? 2025, the iconic, muscle-bound sailor is entering the public domain! </p>
          <h4>What does this mean for you?</h4>
          <p>It means it's time to unleash your inner sailor and join the Popeye party! Whether you're a lifelong fan or just discovering Popeye's charm, there's no better time to dive into the fun. Here's why you should hop aboard:</p>
          <h4>1. Spinach-Powered Adventures:</h4>
          <p>Popeye's legendary strength and unyielding spirit are fueled by spinach – and now, you can be too! Embrace the fun, strength, and vitality that comes with being part of the Popeye crew.</p>
          <h4>2. Create Your Own Popeye Stories:</h4>
          <p>Ever dreamt of creating your own Popeye comic, animation, or meme? Now you can! With Popeye in the public domain, the possibilities are endless. Let your creativity run wild and share your unique take on this classic character.</p>
          <h4>So, What Are You Waiting For?</h4>
          <p>Grab a can of spinach, flex those muscles, and get ready to embark on a spinach-powered adventure with Popeye! The seas are calm, the skies are clear, and the future is bright for all things Popeye.</p>
          <h4>Welcome to the Popeye Public Domain Party!</h4>
        </div>
        <img src={image4} alt="Image 4" className="image large right" />
      </div>
    </div>
  );
};

export default About;
