// src/components/Roadmap/Roadmap.js

import React from 'react';
import './Roadmap.css';
import phase1Image from './images/p1.png'; // Adjust the path to your image file
import phase2Image from './images/p2.png'; // Adjust the path to your image file
import phase3Image from './images/p3.png'; // Adjust the path to your image file

const Roadmap = () => {
  return (
    <div className="roadmap">
      <h2 className="roadmap-headline">Roadmap</h2>
      <div className="roadmap-phases">
        <div className="roadmap-phase">
          <h3>Phase 1</h3>
          <div className="roadmap-phase-content">
            <div className="roadmap-phase-image">
              <img src={phase2Image} alt="Phase 1" />
            </div>
            <div className="roadmap-phase-details">
              <ul>
                <li>Launch on Moonshot</li>
                <li>Marketing campaign</li>
                <li>1,000+ holders</li>
                <li>Community building</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="roadmap-phase">
          <h3>Phase 2</h3>
          <div className="roadmap-phase-content">
            <div className="roadmap-phase-image">
              <img src={phase1Image} alt="Phase 2" />
            </div>
            <div className="roadmap-phase-details">
              <ul>
                <li>Community Growth</li>
                <li>Marketing campaign</li>
                <li>2,500+ holders</li>
                <li>Competitions</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="roadmap-phase">
          <h3>Phase 3</h3>
          <div className="roadmap-phase-content">
            <div className="roadmap-phase-image">
              <img src={phase3Image} alt="Phase 3" />
            </div>
            <div className="roadmap-phase-details">
              <ul>
                <li>Start Partnerships</li>
                <li>10,000+ holders</li>
                <li>Meme Takeover</li>
                <li>Continuous improvement</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Roadmap;
