import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Toconomics from './components/Toconomics/Toconomics'; // Import the Toconomics component
import Stamp from './components/Stamp/Stamp'; // Import the Toconomics component
import About from './components/About/About'; // Import the Toconomics component
import Roadmap from './components/Roadmap/Roadmap';
import './App.css';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Header />
      <Toconomics /> {/* Add the Toconomics component */}
      <About />
      <Roadmap />
      <Stamp />
      
      <Footer />
    </div>
  );
}

export default App;
