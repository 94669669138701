// src/components/Navbar/Navbar.js

import React from 'react';
import './Navbar.css';
import { FaTelegramPlane } from 'react-icons/fa';
import { FaXTwitter } from "react-icons/fa6";
import logo from './images/logo.png'; // Import the logo image
import main from './images/main.png';

const Navbar = () => {
  return (
    <nav className="navbar">
      
      <div className="navbar-logo">
        <img src={main} alt="logo" />
        <img src={logo} alt="Logo" />
      </div>
      <div className="navbar-links-container">
        <ul className="navbar-links">
  

        </ul>
      </div>
      <div className="navbar-socials">
        <a href="https://t.me/your-telegram" target="_blank" rel="noopener noreferrer">
          <FaTelegramPlane />
        </a>
        <a href="https://twitter.com/your-twitter" target="_blank" rel="noopener noreferrer">
          <FaXTwitter />
        </a>
      </div>
    </nav>
  );
};

export default Navbar;
