// src/components/Footer/Footer.js

import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
       <p>© 2024 Popeye meme is not affiliated with Warner Bros. All rights reserved.</p>
    </footer>
  );
};

export default Footer;
